<template>
  <div id="pending_accounts">
    <div class="wrapper-main">
      <div class="wrapper-content">
        <p>{{ $t('pendingAccounts.errorDoc') }}</p>
        <p>{{ $t('pendingAccounts.acceptedDocType') }}</p>
        <div class="info-box">
          <div class="top">
            <div class="primary">
              <h3>
                <img src="@/assets/images/pendingaccounts/star.png" alt="" />{{ $t('pendingAccounts.primaryDoc') }}
              </h3>
              <div class="line">
                <div class="img">
                  <img src="@/assets/images/pendingaccounts/id.png" alt="" />
                </div>
                <ul>
                  <li>
                    <img src="@/assets/images/pendingaccounts/check.png" alt />{{ $t('pendingAccounts.passport') }}
                  </li>
                  <li><img src="@/assets/images/pendingaccounts/check.png" alt />{{ $t('pendingAccounts.id') }}</li>
                  <li><img src="@/assets/images/pendingaccounts/check.png" alt />{{ $t('pendingAccounts.dl') }}</li>
                </ul>
              </div>
            </div>
            <div class="secondary">
              <h3><img src="@/assets/images/pendingaccounts/star.png" alt="" />{{ $t('pendingAccounts.address') }}</h3>
              <div class="line">
                <div class="img">
                  <img src="@/assets/images/pendingaccounts/poa.png" alt="" />
                </div>
                <ul>
                  <li v-for="(list, index) in $t('pendingAccounts.addressList')" :key="index">
                    <img src="@/assets/images/pendingaccounts/check.png" alt />
                    {{ list }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <p>
            {{ $t('pendingAccounts.tip') }}
            <a :href="'mailto:' + GLOBAL_ACCOUNT_EMAIL">{{ GLOBAL_ACCOUNT_EMAIL }}</a>
          </p>
        </div>
        <div class="table-content">
          <div class="table-box">
            <el-table :data="displayData" style="width: 100%;">
              <el-table-column :label="$t('common.keys.DATE')" min-width="120">
                <template slot-scope="scope">{{ scope.row.register_date | date }}</template>
              </el-table-column>
              <el-table-column prop="applyName" :label="$t('common.keys.NAME')" min-width="120"></el-table-column>
              <el-table-column prop="email" :label="$t('common.keys.EMAIL')" min-width="120"></el-table-column>
              <el-table-column :label="$t('common.keys.ACCTYPE')" min-width="120">
                <template slot-scope="scope">
                  {{ $config.accountTypeMaps[scope.row.account_type] }}
                </template>
              </el-table-column>
              <el-table-column
                prop="mt4_datasource"
                :label="$t('common.keys.PLATFORM')"
                min-width="120"
              ></el-table-column>
              <el-table-column
                prop="apply_currency"
                :label="$t('common.keys.BASECURRENCY')"
                min-width="120"
              ></el-table-column>
            </el-table>
          </div>
          <Pagination v-bind:table-data="tableData" v-bind:display-data.sync="displayData"></Pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetPendingAccount } from '@/resource';
import Pagination from '@/components/Pagination';

export default {
  components: { Pagination },
  data() {
    return {
      // 分页
      userID: this.$store.state.common.CUID,
      total: 0,
      displayData: [],
      tableData: []
    };
  },
  mounted() {
    this.queryPendingUser();
  },
  methods: {
    queryPendingUser() {
      apiGetPendingAccount({
        pid: this.userID
      }).then(response => {
        this.tableData = response.data;
        this.total = this.tableData.length;
      }, this.$handleError);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/pendingAccounts.scss';
</style>
